import CustomTitle from '@/components/CustomTitle';
import Loader from '@/components/Loader';
import useAuth from '@/context/authContext/useAuth';
import useThemeContext from '@/context/themeContext/useThemeContext';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AnimatePresence, LazyMotion, domAnimation } from 'framer-motion';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

const IndexPage = lazy(() => import('@/scenes/indexPage'));
const Login = lazy(() => import('@/scenes/login'));

const CreateTicketer = lazy(() => import('@/scenes/createTicketer'));
const Topbar = lazy(() => import('@/components/TopBar'));
const Ticketer = lazy(() => import('@/scenes/ticketer'));
const Ticketers = lazy(() => import('@/scenes/ticketers'));
const QrScanner = lazy(() => import('@/scenes/qrScanner'));

function App() {
  const { theme } = useThemeContext();
  const location = useLocation();

  const { isLoggedIn } = useAuth();

  return (
    <ThemeProvider theme={theme!}>
      <CssBaseline />

      <LazyMotion features={domAnimation} strict>
        <div className='App'>
          <CustomTitle>Dance Wave</CustomTitle>

          <Suspense fallback={<Loader />}>
            <main className='content'>
              <AnimatePresence mode='wait'>
                <Routes location={location} key={location.pathname}>
                  {isLoggedIn ? (
                    <>
                      <Route index element={<Navigate to='/ticketers' />} />
                      <Route
                        path='/ticketers'
                        element={
                          <>
                            <Topbar />
                            <Ticketers />
                          </>
                        }
                      />
                      <Route
                        path='/ticketers/create'
                        element={
                          <>
                            <Topbar />
                            <CreateTicketer />
                          </>
                        }
                      />

                      <Route path='/qr' element={<QrScanner />} />
                    </>
                  ) : (
                    <>
                      <Route index element={<IndexPage />} />
                      <Route path='auth/login' element={<Login />} />
                    </>
                  )}

                  <Route path='/ticketers/:id' element={<Ticketer />} />

                  <Route path='*' element={<Navigate to='/' />} />
                </Routes>
              </AnimatePresence>
            </main>
          </Suspense>
        </div>
      </LazyMotion>
    </ThemeProvider>
  );
}

export default App;
