export enum ApiErrorCode {
  UNKNOWN_ERROR,

  NOT_FOUND,

  AUTH_REQUIRED,
  INVALID_CREDENTIALS,

  WRONG_USERNAME,
  WRONG_PASSWORD,

  INVALID_SNOWFLAKE,

  INVALID_CONTENT_TYPE,

  INVALID_FIRSTNAME,
  INVALID_LASTNAME,
  // INVALID_EMAIL,
  // INVALID_LANGUAGE,
  // INVALID_DISCOUNT,
  // INVALID_SPECIAL_DISCOUNT,
  INVALID_IS_ON_PARTY,
  // INVALID_CHILDS,
  // INVALID_GUARDIANS,
  // INVALID_PAIR,

  // EMAIL_ALREADY_EXISTS,
  // EMAIL_NOT_SET,
}

export default ApiErrorCode;
