import useAuth from '@/context/authContext/useAuth';
import { ApiResponse, IApiResponse } from '@/types/ApiResponse.interface';
import ApiErrorCode from '@/types/enums/ApiErrorCode.enum';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

declare module 'axios' {
  export interface AxiosRequestConfig {
    file?: boolean;
  }
}

declare global {
  interface Window {
    baseUrl: string;
  }
}

window.baseUrl = `${window.location.protocol}//${
  window.location.hostname === 'localhost'
    ? 'localhost:785'
    : 'api.dancewave.ee'
}`;

console.info(`API URL: ${window.baseUrl}`);

export function useAxios() {
  const { isLoggedIn, authToken } = useAuth();
  const navigate = useNavigate();

  const request = useCallback(
    async function <ResPayload>(config: AxiosRequestConfig) {
      const { headers, auth, ...rest } = config;

      console.log(
        `[Request] ${(config.method || 'GET').toUpperCase()} ${config.url}`,
        config.data,
      );

      const res = await axios
        .request<IApiResponse<ResPayload>>({
          baseURL: window.baseUrl,
          headers: {
            'Content-Type': 'application/json',
            ...(!auth && isLoggedIn && { Authorization: `Basic ${authToken}` }),
            ...headers,
          },
          validateStatus: () => true,
          auth,
          ...rest,
        })
        .then((res) => {
          console.log(
            `[API] ${res.status} ${res.statusText} ${res.config.url}`,
            res.data,
          );

          if (res.data.errors?.includes(ApiErrorCode.AUTH_REQUIRED)) {
            console.warn('Auth required, redirecting to login page');

            return navigate('/auth/login', {
              state: { from: window.location.pathname },
            });
          } else if (
            res.data.errors?.includes(ApiErrorCode.INVALID_CREDENTIALS)
          ) {
            console.warn('Invalid credentials, logging out');

            return navigate('/auth/login', {
              state: { from: window.location.pathname },
            });
          }

          return new ApiResponse<ResPayload>(res);
        })
        .catch((error: AxiosError) => {
          if (error.name === 'CanceledError') return;
          else if (error.message === 'Network Error') {
            return console.error('[API] Network Error');
          }

          console.error(
            `[API] ${error.name} ${error.message} ${error.config?.url} ${error.status}`,
          );
        });

      if (!res || !res.isOk) throw res;

      return res;
    },
    [authToken, isLoggedIn, navigate],
  );

  return request;
}

export default useAxios;
