import Loader from '@/components/Loader';
import { AuthContextProvider } from '@/context/authContext/context';
import { ColorModeProvider } from '@/context/themeContext/context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <SnackbarProvider autoHideDuration={2000} dense preventDuplicate>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <ColorModeProvider>
                <App />
              </ColorModeProvider>
            </AuthContextProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </Suspense>
  </StrictMode>,
);
