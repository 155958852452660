import useCustomTitle from '@/hooks/useCustomTitle';

type CustomTitleProps = {
  children: string | string[];
};
export function CustomTitle({ children }: CustomTitleProps) {
  const title = Array.isArray(children) ? children.join('') : children;

  useCustomTitle(title);

  return <></>;
}

export default CustomTitle;
