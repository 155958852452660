import { AxiosResponse } from 'axios';
import ApiErrorCode from './enums/ApiErrorCode.enum';

export interface IApiResponse<T = unknown> {
  data?: T;
  errors?: ApiErrorCode[];
  path: string;
  method: string;
  statusCode: number;
  statusText: string;
  timestamp: string;
}

export class ApiResponse<T = unknown> {
  public readonly response: AxiosResponse<IApiResponse<T>>;

  public readonly data?: T;
  public readonly errors?: ApiErrorCode[];
  public readonly path: string;
  public readonly method: string;
  public readonly statusCode: number;
  public readonly statusText: string;
  public readonly timestamp: string;

  constructor(response: AxiosResponse<IApiResponse<T>>) {
    this.response = response;

    const { file = false } = response.config;

    // @ts-ignore
    this.data = file ? response.data : response.data.data;
    // @ts-ignore
    this.errors = file ? undefined : response.data.errors;
    // @ts-ignore
    this.path = file ? response.config.url || '/' : response.data.path;
    this.method = response.config.method?.toUpperCase() || 'GET';
    // @ts-ignore
    this.statusCode = file ? response.status : response.data.statusCode;
    // @ts-ignore
    this.statusText = file ? response.statusText : response.data.statusText;
    // @ts-ignore
    this.timestamp = file ? new Date().toISOString() : response.data.timestamp;
  }

  public get timestampAsDate(): Date {
    return new Date(this.timestamp);
  }

  public get isOk(): boolean {
    return !this.errors || this.errors.length === 0;
  }
}
