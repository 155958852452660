import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import { MotionCircularProgress } from './Motion';

type LoaderProps = {
  inline?: boolean;
  circleProps?: Parameters<typeof MotionCircularProgress>[0];
};
export default function Loader({ inline = false, circleProps }: LoaderProps) {
  const theme = useTheme();

  return inline ? (
    <CircularProgress color={'secondary'} {...circleProps} />
  ) : (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.default,
      }}
      open
    >
      <MotionCircularProgress
        transition={{ duration: 0.5 }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        color={'secondary'}
        {...circleProps}
      />
    </Backdrop>
  );
}
