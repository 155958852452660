import { useAxios } from '@/hooks/useAxios';
import { ApiResponse } from '@/types/ApiResponse.interface';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

type AuthContextProps = {
  isLoggedIn: boolean;
  authToken?: string;

  logouted: boolean;
  setLogouted: (value: boolean) => void;

  loginWithBasicAuth: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,

  logouted: false,
  setLogouted: () => {},

  loginWithBasicAuth: async () => {},
  logout: async () => {},
});

export function AuthContextProvider(props: PropsWithChildren) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState<string>();
  const [logouted, setLogouted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');

    if (authToken) {
      setAuthToken(authToken);
      setIsLoggedIn(true);
    }
  }, []);

  const axios = useAxios();

  const loginWithBasicAuth = useCallback(
    async (username: string, password: string) =>
      axios<void>({
        method: 'POST',
        url: '/auth/login',
        auth: { username, password },
      })
        .then((res) => {
          console.log('Login successful: ', res);

          const authToken = btoa(`${username}:${password}`);

          localStorage.setItem('auth_token', authToken);
          setAuthToken(authToken);
          setIsLoggedIn(true);

          return;
        })
        .catch((error: ApiResponse) => {
          console.error('Login error: ', error);

          localStorage.removeItem('auth_token');
          setAuthToken(undefined);
          setIsLoggedIn(false);

          throw error;
        }),
    [axios, setIsLoggedIn, setAuthToken],
  );

  const logout = useCallback(async () => {
    localStorage.removeItem('auth_token');
    navigate('/');
    setIsLoggedIn(false);
    setLogouted(true);
    console.log('Logout successful');
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logouted,
        setLogouted,
        authToken,
        loginWithBasicAuth,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
