import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { ColorMode, colors } from './types';

// mui theme settings
function getColorsPalette(mode: ColorMode) {
  const paletteColors = colors[mode];

  const map = {
    dark: {
      primary: {
        main: paletteColors.primary[500],
        ...paletteColors.primary,
      },
      secondary: {
        main: paletteColors.greenAccent[500],
        ...paletteColors.greenAccent,
      },
      // neutral: {
      //   dark: paletteColors.grey[700],
      //   main: paletteColors.grey[500],
      //   light: paletteColors.grey[100],
      // },
      background: {
        default: paletteColors.primary[500],
      },
      grey: paletteColors.grey,
      error: {
        main: paletteColors.redAccent[500],
        ...paletteColors.redAccent,
      },
      info: {
        main: paletteColors.blueAccent[500],
        ...paletteColors.blueAccent,
      },
      text: {
        primary: paletteColors.grey[100],
        secondary: paletteColors.grey[500],
        disabled: paletteColors.grey[300],
      },
    },
    light: {
      primary: {
        main: paletteColors.primary[100],
        ...paletteColors.primary,
      },
      secondary: {
        main: paletteColors.greenAccent[500],
        ...paletteColors.greenAccent,
      },
      // neutral: {
      //   dark: paletteColors.grey[700],
      //   main: paletteColors.grey[500],
      //   light: paletteColors.grey[100],
      // },
      background: {
        default: '#fcfcfc',
      },
      grey: paletteColors.grey,
      error: {
        main: paletteColors.redAccent[500],
        ...paletteColors.redAccent,
      },
      info: {
        main: paletteColors.blueAccent[500],
        ...paletteColors.blueAccent,
      },
      text: {
        primary: paletteColors.grey[100],
        secondary: paletteColors.grey[500],
        disabled: paletteColors.grey[300],
      },
    },
  };

  return map[mode];
}

function getThemeSettings(mode: ColorMode): ThemeOptions {
  const colorsPalette = getColorsPalette(mode);

  return {
    palette: {
      mode,
      ...colorsPalette,
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colorsPalette.grey[100],

            '&.Mui-focused': {
              color: colorsPalette.grey[100],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: { borderColor: colorsPalette.grey[600] },
          root: {
            '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: `${colorsPalette.grey[200]} !important`,
            },
            '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
              borderColor: `${colorsPalette.text.primary} !important`,
            },

            '&.Mui-error > .MuiOutlinedInput-notchedOutline': {
              borderColor: `${colorsPalette.error.main} !important`,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: colorsPalette.grey[600],
            },
            '&:hover fieldset': {
              borderColor: colorsPalette.grey[200],
            },
            '&.Mui-focused fieldset': {
              borderColor: colorsPalette.text.primary,
            },

            '&.Mui-error fieldset': {
              borderColor: colorsPalette.error.main,
            },
            '&.Mui-error:hover fieldset': {
              borderColor: colorsPalette.error.main,
            },
            '&.Mui-error.Mui-focused fieldset': {
              borderColor: colorsPalette.error.main,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: { '& svg': { fill: colorsPalette.grey[100] } },
        },
      },
      // MuiFormControlLabel: {
      //   styleOverrides: {
      //     root: {
      //       justifyContent: 'center',
      //       marginRight: 0,
      //       marginLeft: 0,
      //     },
      //   },
      // },
      MuiSwitch: {
        styleOverrides: {
          track: { backgroundColor: colorsPalette.grey[400] },
          root: {
            '& .MuiSwitch-switchBase': {
              color: `${colorsPalette.grey[400]} !important`,

              '&.Mui-checked': {
                color: `${colorsPalette.grey[100]} !important`,

                '& + .MuiSwitch-track': {
                  backgroundColor: `${colorsPalette.grey[400]} !important`,
                },
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary': {
              backgroundColor: colorsPalette.secondary[700],
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: colorsPalette.error[700],
              color: colorsPalette.text?.primary,

              '&.Mui-disabled': {
                color: colorsPalette.text?.disabled,
              },
            },

            '&.Mui-checked': {
              color: colorsPalette.grey[100],

              '& + .MuiSwitch-track': {
                backgroundColor: colorsPalette.grey[100],
              },
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
      fontSize: 12,

      h1: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 16,
      },
      h6: {
        fontFamily: ['"Source Sans 3"', 'sans-serif'].join(','),
        fontSize: 14,
      },
    },
  };
}

export type ColorModeContextProps = {
  theme: Theme;
  changeColorMode: (mode: ColorMode) => void;
  toggleColorMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextProps>({
  theme: createTheme(getThemeSettings(ColorMode.Dark)),
  changeColorMode: () => {},
  toggleColorMode: () => {},
});

export function ColorModeProvider(props: PropsWithChildren) {
  const [mode, setMode] = useState<ColorMode>(ColorMode.Dark);

  const theme = useMemo(() => createTheme(getThemeSettings(mode)), [mode]);

  const value = {
    theme,
    changeColorMode: setMode,
    toggleColorMode: () =>
      setMode((prevMode) =>
        prevMode === ColorMode.Dark ? ColorMode.Light : ColorMode.Dark,
      ),
  };

  return (
    <ColorModeContext.Provider value={value}>
      {props.children}
    </ColorModeContext.Provider>
  );
}
