import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { m } from 'framer-motion';
import { forwardRef } from 'react';

export type AnimsProps = {
  transition?: Parameters<typeof MotionBox>[0]['transition'];
  initial?: Parameters<typeof MotionBox>[0]['initial'];
  animate?: Parameters<typeof MotionBox>[0]['animate'];
  exit?: Parameters<typeof MotionBox>[0]['exit'];
};

const CustomBox = forwardRef<typeof Box, BoxProps>((props, ref) => (
  <Box ref={ref} {...props} />
));
const CustomTypography = forwardRef<typeof Typography, TypographyProps>(
  // @ts-ignore
  (props, ref) => <Typography ref={ref} {...props} />,
);
const CustomButton = forwardRef<typeof Button, ButtonProps>((props, ref) => (
  // @ts-ignore
  <Button ref={ref} {...props} />
));
const CustomCircularProgress = forwardRef<
  typeof CircularProgress,
  CircularProgressProps
>((props, ref) => <CircularProgress ref={ref} {...props} />);

export const MotionBox = m(CustomBox);
export const MotionTypography = m(CustomTypography);
export const MotionButton = m(CustomButton);
export const MotionCircularProgress = m(CustomCircularProgress);
