import { useEffect, useMemo } from 'react';

export function useCustomTitle(title: string) {
  const initialTitle = useMemo(() => document.title, []);

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = initialTitle;
    };
  }, [title, initialTitle]);
}

export default useCustomTitle;
